import React from "react";
import { SEO, PageLayout, Image, ServicesList } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"services-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              componentIdentifier: "background",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid stackable={true} textAlign={"center"}>
            <Grid.Column
              computer={6}
              tablet={8}
              className={"services-content-container"}
              textAlign={"left"}
            >
              <Image
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
                background={true}
              />
              <div className={"services-list-container"}>
                <Header as={"h1"}>Services</Header>
                <ServicesList
                  defaultAllOpen={true}
                  priceDisplayType={"range"}
                  catalog={{
                    schedules: allFishermanBusinessMenuSchedule.nodes,
                    categories: allFishermanBusinessMenuCategory.nodes,
                    items: allFishermanBusinessMenuItem.nodes,
                  }}
                />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        categories
      }
    }
    allFishermanBusinessMenuCategory(sort: { order: ASC, fields: order }) {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem(sort: { order: ASC, fields: order }) {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
  }
`;
